import {
	animated,
	config,
	SpringValue,
	useSpring,
	useTrail,
	useTransition,
} from '@react-spring/web'
import useComponentSize from '@rehooks/component-size'
import clsx from 'clsx'
import { clamp as _clamp, last as _last } from 'lodash'
import NextImage from 'next/image'
import { useEffect, useRef, useState } from 'react'

import ChromeToolbar from '~/components/hero/chrome-toolbar'
import { useViewportProgress } from '~/components/hooks/use-viewport-progress'

export default ({
	className,
	Image = NextImage,
	hideText,
	frame,
	...props
}: React.HTMLAttributes<HTMLDivElement>) => {
	const [notifications, setNotifications] = useState<NotificationProps[]>([])
	const [isTriggered, setIsTriggered] = useState(false)
	const [spring, globalApi] = useSpring(() => ({ progress: 0 }))
	const element = useRef<HTMLDivElement>(null!)
	const ref = useRef<HTMLDivElement>(null!)
	const [otpSprings, otpSpringsApi] = useTrail(6, () => ({
		from: { opacity: 0, y: -10 },
	}))
	const [keyboardSprings, keyboardSpringsApi] = useTrail(2, idx => ({
		config: { ...config.stiff, clamp: true },
		from: { y: 0 },
		async onRest(result, ctrl) {
			if (idx === 1 && result.value.y === 1) {
				await keyboardSpringsApi.start(() => ({ y: 0 }))
				otpSpringsApi.start(() => ({
					opacity: result.value.y,
					y: result.value.y * 10 - 10,
				}))
			}
		},
	}))
	useViewportProgress({
		onChange({ value: { progressY: progress } }) {
			// match 0.34 with the height of the div
			const globalProgress = _clamp(progress - 0, 0, 1) / (1 - 1.66 * 0)
			globalApi.start(() => ({
				immediate: true,
				progress: globalProgress,
			}))
			if (frame === undefined) setIsTriggered(globalProgress >= 0.2)
		},
		ref,
	})
	useEffect(() => {
		if (frame) setIsTriggered(frame > 1)
	}, [frame])
	useEffect(() => {
		if (isTriggered) {
			setNotifications([
				{
					body: "Your requested one-time passcode is 123456. Don't share it with anyone and good luck remembering it! Seriously, godspeed!",
					id: 'otp',
					title: 'Bank of The Big Easy',
				},
			])
		} else {
			keyboardSpringsApi.start(() => ({ immediate: true, y: 0 }))
			otpSpringsApi.start(() => ({ immediate: true, opacity: 0, y: -10 }))
			setNotifications([])
		}
	}, [isTriggered])
	const transitions = useTransition(notifications, {
		config: (item, _index, phase) => key => {
			if (key === 'isDisplaying') return { duration: 3000 }
			switch (item.id) {
				case 'otp': {
					if (phase === 'enter' && key === 'isScanning') {
						return { duration: 2000 }
					}

					return config.default
				}

				default: {
					return config.default
				}
			}
		},

		enter: item => async next => {
			await next({ opacity: 1, x: `0%` })
			if (item.id === 'otp') {
				await next({ immediate: true, isScanning: 1 })
				await next({ isScanning: 0 })
				setNotifications(notifications => [
					...notifications,
					{
						body: 'Found 123456 and copied to clipboard.',
						iconSrc: '/icon_512x512@2x.png',
						id: 'md',
						title: 'Code Copied to Clipboard',
					},
				])
				await next({ isDisplaying: 1 })
			} else {
				setTimeout(() => {
					void keyboardSpringsApi.start(() => ({ y: 1 }))
				}, 1000)
				await next({ isDisplaying: 1 })
			}
		},
		expires: true,
		from: { isDisplaying: 0, isScanning: 0, opacity: 0, x: `100%` },
		keys: item => item.id,
		leave: { isDisplaying: 0, isScanning: 0, opacity: 0, x: `100%` },
		onRest(_result, _ctrl, item) {
			setNotifications(notes => notes.filter(n => n.id !== item.id))
		},
	})
	return (
		<div className={clsx('', className)} {...props}>
			<div className="relative flex flex-1 flex-col" ref={ref}>
				<div
					className={clsx(
						'flex flex-col items-center justify-between overflow-hidden bg-white',
						hideText ? 'min-h-full' : 'min-h-[1000px] w-screen'
					)}
					ref={element}
				>
					{hideText ? null : (
						<h1 className="mb-16 mt-8 shrink-0 px-4 font-mono text-3xl font-bold tracking-tighter md:text-6xl">
							Now Available Everywhere on{' '}
							<span className="font-display tracking-normal">Mac</span>
						</h1>
					)}
					<div className="relative flex w-full max-w-6xl flex-1 flex-col items-start overflow-visible">
						<div className="z-20 -ml-10 flex h-full w-[100vw] max-w-md scale-100 flex-col gap-y-4 overflow-hidden p-4 pt-16 md:ml-auto md:mr-12 md:scale-100 md:pt-16">
							{transitions(({ isScanning, ...style }, item) => (
								<Notification
									{...item}
									Image={Image}
									isScanning={isScanning}
									style={style}
								/>
							))}
						</div>
						<div
							className={clsx(
								'absolute top-0 z-10 flex aspect-[15/11] w-[210vw] max-w-4xl flex-col overflow-visible pt-16',
								hideText ? null : 'right-16'
							)}
						>
							<div className="relative flex flex-1 flex-col rounded-xl bg-white shadow-lg">
								<ChromeToolbar className="flex" />
								<div
									className={clsx(
										'absolute z-10 flex gap-x-3',
										hideText
											? 'md:-bottom-56 md:-right-96'
											: '-bottom-20 -right-16 md:-bottom-8 md:-right-8'
									)}
								>
									<div className="relative z-0 flex scale-75 items-center gap-x-4 rounded-2xl border border-gray-200 bg-gray-800 p-8 text-gray-700 sm:scale-90">
										<div className="relative flex flex-col pb-2">
											<div className="absolute top-2 z-0 flex h-24 w-32 rounded-xl bg-gray-300" />
											<animated.div
												style={{
													y: keyboardSprings[0].y.to(y => 4 * 1.5 * y),
												}}
												className="z-10 flex h-24 w-32 flex-col items-end justify-between rounded-xl border border-gray-300 bg-white p-2"
											>
												<span className="text-2xl">⌘</span>
												<span>command</span>
											</animated.div>
										</div>
										<span className="text-4xl font-bold text-gray-300">+</span>
										<div className="relative flex flex-col pb-2">
											<div className="absolute top-2 z-0 flex h-24 w-24 rounded-xl bg-gray-300" />
											<animated.div
												style={{
													y: keyboardSprings[1].y.to(y => 4 * 1.5 * y),
												}}
												className="z-10 flex h-24 w-24 flex-col items-center justify-center rounded-xl border border-gray-300 bg-white p-2 uppercase"
											>
												<span className="text-3xl">v</span>
											</animated.div>
										</div>
									</div>
								</div>
								<div className="z-0 flex flex-1 flex-col items-center justify-center rounded-b-xl bg-gray-100">
									<div className="flex h-64 w-full max-w-sm flex-col items-center justify-center gap-y-6 rounded-lg bg-white shadow-lg">
										<h2 className="text-lg font-bold">Enter One-Time Passcode</h2>
										<div className="flex h-12 w-64 gap-x-2">
											{otpSprings.map((spring, idx) => (
												<div className="flex flex-1 items-center justify-center overflow-hidden rounded border text-2xl">
													<animated.span style={spring}>{idx + 1}</animated.span>
												</div>
											))}
										</div>
										<span className="w-full max-w-[16rem] rounded-md bg-white px-3.5 py-2.5 text-center text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300">
											Login
										</span>
									</div>
								</div>
							</div>
						</div>
						<div
							className={clsx(
								'absolute top-0 z-0 aspect-[6080/3674] h-full min-h-full',
								hideText ? '-right-24' : '-right-24'
							)}
						>
							<Image
								alt="MacBook Pro"
								className="object-cover object-right-top"
								src="/mbp-14-sonoma@2x.jpg"
								unoptimized // NB: don't sweat this, it's 300kb and lazy loaded and we want crisp pixels
								fill
							/>
						</div>
					</div>
				</div>
				{hideText ? null : (
					<div className="font-display mx-auto mt-36 flex max-w-6xl shrink-0 flex-col px-4 text-4xl font-bold md:text-7xl">
						<h1 className="text-4xl font-bold md:text-7xl">
							Introducing,{' '}
							<span>
								<span className="font-display tracking-tight">Message De</span>
								<span className="font-mono tracking-[-0.1em]">code</span>
								<span className="font-display tracking-tight">r</span>
							</span>
						</h1>
						<h2 className="mt-8 font-normal text-amber-500 md:text-5xl">
							Up to <span className="font-black">15x faster</span>, fully automated{' '}
							<span className="font-mono tracking-tighter">one-time passcode</span> entry,
							right from your Mac's clipboard.
						</h2>
					</div>
				)}
			</div>
		</div>
	)
}

type NotificationProps = {
	body?: string
	id: string
	iconSrc?: string
	isScanning?: SpringValue<number>
	title: string
}

const Notification = ({
	body,
	children,
	className,
	id,
	iconSrc,
	Image,
	isScanning = new SpringValue(0),
	style,
	title,
	...props
}: React.HTMLAttributes<HTMLDivElement> & NotificationProps) => {
	const ref = useRef<HTMLDivElement>(null!)
	const { width } = useComponentSize(ref)
	return (
		<animated.div
			className={clsx(
				'relative flex w-full gap-x-2 rounded-2xl bg-[#F6F6F6] bg-opacity-[86%] px-4 py-3 shadow-lg',
				className
			)}
			ref={ref}
			style={{
				...style,
			}}
			{...props}
		>
			<animated.div
				className="animate-scan absolute left-0 top-0 h-full w-1 bg-amber-400 shadow-sm"
				style={{
					'--width': `${width}px`,
					opacity: isScanning.to(p => (p > 0 ? 1 : 0)),
				}}
			/>
			{iconSrc ? (
				<div className="relative my-auto flex aspect-square w-11 ">
					<Image alt="app icon" src={iconSrc} fill />
				</div>
			) : (
				<div className="m-1 my-auto flex aspect-square w-9 rounded-lg bg-gray-400 shadow-md" />
			)}
			<div className="flex flex-1 flex-col">
				<div className="flex items-baseline justify-between">
					<span className="text-sm font-bold">{title}</span>
					<span className="text-xs text-gray-600">now</span>
				</div>
				{body ? <span className="mb-1 text-sm leading-4">{body}</span> : children}
			</div>
		</animated.div>
	)
}
