import { track } from '@vercel/analytics'
import clsx from 'clsx'
import Link from 'next/link'
import { signIn } from 'next-auth/react'

import { AppleLogo, GoogleLogo } from '~/components/logo'

export default ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
	return (
		<div className={clsx('bg-white', className)} {...props}>
			<div className="mx-auto max-w-7xl pt-24 sm:px-6 sm:py-32 lg:px-8">
				<div className="relative isolate flex-col overflow-hidden bg-gray-900 px-6 py-16 shadow-2xl sm:rounded-3xl sm:px-16 md:py-24 lg:flex lg:px-24 lg:pt-0">
					<div className="flex lg:gap-x-20">
						<svg
							viewBox="0 0 1024 1024"
							className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
							aria-hidden="true"
						>
							<circle
								cx={512}
								cy={512}
								r={512}
								fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
								fillOpacity="0.7"
							/>
							<defs>
								<radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
									<stop stopColor="#F9DD61" />
									<stop offset={1} stopColor="#885F35" />
								</radialGradient>
							</defs>
						</svg>
						<div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:pt-32 lg:text-left">
							<h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
								Unlimited free scans.
								<br />
								3-click setup.
								<br />
								100% local processing.
								<br />
								<span className="text-amber-500">Get started now.</span>
							</h2>
							<p className="mt-6 text-lg leading-8 text-gray-300">
								Message Decoder is a native macOS app that scans every notification on
								your screen, parses one-time passcodes using a totally custom and 100%
								private on-device Machine Learning model, and puts the codes directly on
								your clipboard.
								<br />
								<span className="font-bold">
									Instead of breaking flow to track down the code, you just hit paste.
								</span>
							</p>
						</div>
						<div className="relative my-auto mt-16 flex aspect-square flex-1 lg:-mr-12 lg:mt-32">
							<img
								className="absolute left-0 top-0 w-full max-w-none"
								src="/icon_512x512@2x.png"
								alt="App icon"
							/>
						</div>
					</div>
					<div className="mt-10 flex flex-col items-center justify-center gap-6 md:flex-row lg:justify-start">
						<button
							className="rounded-md bg-black px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
							onClick={async () => {
								track('sign-in', { provider: 'apple' })
								return signIn('apple', {
									callbackUrl: `${window.location.origin}/home`,
								})
							}}
							type="button"
						>
							<AppleLogo
								className="absolute inline-flex aspect-square h-6 text-white"
								currentColor
							/>
							<span className="ml-9 w-full font-medium">Sign Up with Apple</span>
						</button>
						<button
							className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
							onClick={async () => {
								track('sign-in', { provider: 'google' })
								return signIn('google', {
									callbackUrl: `${window.location.origin}/home`,
								})
							}}
							type="button"
						>
							<GoogleLogo className="absolute inline-flex aspect-square h-6" />
							<span className="ml-9 w-full font-medium">Sign Up with Google</span>
						</button>
						<Link
							href="/download/macos"
							className="hidden text-sm font-semibold leading-6 text-white md:flex"
							onClick={() => {
								track('download', { platform: 'macos' })
							}}
						>
							Download for macOS <span aria-hidden="true">→</span>
						</Link>
					</div>
					<p className="mt-3 text-center text-sm leading-8 text-gray-300 lg:text-left">
						Requires macOS 11.0 or later.
					</p>
				</div>
			</div>
		</div>
	)
}
