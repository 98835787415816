import { animated, useSpring, useSprings } from '@react-spring/web'
import useComponentSize from '@rehooks/component-size'
import clsx from 'clsx'
import { clamp as _clamp, last as _last } from 'lodash'
import { useRef } from 'react'
import { useMediaQuery } from 'usehooks-ts'

import { useViewportProgress } from '~/components/hooks/use-viewport-progress'
import Post, { type PostProps } from '~/components/post'

const posts: PostProps[] = [
	{
		avatarUrl: '/posts/PpAlYA2S_400x400.jpg',
		content: `Isn't this the best iOS feature?`,
		date: 'Sep 3',
		displayName: 'Ilya Miskov',
		handle: 'ilamiskov',
		imageUrl: '/posts/1698291505750851953.jpg',
		likes: '552',
		replies: '67',
		reposts: '21',
		views: '48.8K',
	},
	{
		avatarUrl: '/posts/YsUHG-Ea_400x400.jpg',
		content: 'fr',
		date: 'Aug 28',
		displayName: 'internet hall of fame',
		handle: 'InternetH0F',
		imageUrl: '/posts/1696291152461464022.jpg',
		likes: '298.5K',
		replies: '1,876',
		reposts: '17.5K',
		views: '25.9M',
	},
	{
		avatarUrl: '/posts/S1BEvjDb_400x400.jpg',
		content: 'The most underrated new iOS 17 feature',
		date: 'Sep 2',
		displayName: 'Apple Hub',
		handle: 'theapplehub',
		imageUrl: '/posts/1697888537293824285.jpg',
		likes: '17.8K',
		replies: '473',
		reposts: '1,285',
		views: '2.9M',
	},
	{
		avatarUrl: '/posts/f80RsqPI_400x400.jpg',
		content: 'This is easily the best IOS feature',
		date: 'Aug 29',
		displayName: 'Georgebabs📸',
		handle: 'Georgebabss',
		imageUrl: '/posts/1696438637418668107.jpg',
		likes: '58',
		replies: '38',
		reposts: '45',
		views: '2,602',
	},
	{
		avatarUrl: '/posts/0mmJ_HUi_400x400.jpg',
		content: 'Sick of every other tweet being this',
		date: 'Aug 29',
		displayName: 'Cole en',
		handle: 'coleuen',
		imageUrl: '/posts/1696564660978307205.jpg',
		likes: '2',
		replies: '1',
		// reposts: '17.5K',
		views: '105',
	},
	{
		avatarUrl: '/posts/bnLxch4F_400x400.jpg',
		content: 'this gotta be the best iphone feature ever',
		date: 'Aug 27',
		displayName: 'T-Juan',
		handle: 'babykeef_',
		imageUrl: '/posts/1695876534362394814.jpg',
		// likes: '298.5K',
		replies: '1',
		// reposts: '17.5K',
		views: '142',
	},
]

export default ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
	const isMd = useMediaQuery('(min-width: 768px)')
	const ref = useRef<HTMLDivElement>(null!)
	const cardRef = useRef<HTMLDivElement>(null!)
	const [spring, globalApi] = useSpring(() => ({ progress: 0 }))
	const [springs, api] = useSprings(posts.length - 1, i => ({ progress: 0 }))
	const { height, width } = useViewportProgress({
		onChange({ value: { progressY: progress } }) {
			// match 0.34 with the height of the div
			const globalProgress = _clamp(progress - 0.34, 0, 1) / (1 - 1.66 * 0.34)
			globalApi.start(() => ({
				immediate: true,
				progress: globalProgress,
			}))
			api.start(i => ({
				immediate: true,
				progress: _clamp(globalProgress * (posts.length - 1) - i, 0, 1),
			}))
		},
		ref,
	})
	const { height: cardHeight, width: cardWidth } = useComponentSize(cardRef)
	const distanceX = (isMd ? 1 : 1.2) * (cardHeight / 2 + height / 2)
	const distanceY = (isMd ? 1 : 1.2) * (cardWidth / 2 + width / 2)
	return (
		<div className={clsx('', className)} {...props}>
			<div className="flex h-[200vh] flex-col" ref={ref}>
				<div className="sticky top-0 overflow-x-hidden overflow-y-hidden pb-64 md:pb-0">
					<div className="absolute inset-0 z-0 flex h-screen flex-col items-center justify-between px-4 py-6 md:justify-center md:gap-y-64">
						<h1 className="font-mono text-4xl font-bold tracking-tighter md:text-6xl">
							The World's Most
						</h1>
						<h1 className="flex h-full max-h-[70vh] w-full max-w-5xl flex-col items-center justify-between font-mono text-6xl font-black md:h-auto md:flex-row md:text-6xl">
							<animated.span
								className="md:-ml-12"
								style={{
									letterSpacing: spring.progress.to(p => `${_clamp(p, -0.05, 0.4)}em`),
								}}
							>
								Talked
							</animated.span>
							<animated.span
								style={{
									letterSpacing: spring.progress.to(p => `${_clamp(p, -0.05, 0.4)}em`),
								}}
							>
								About
							</animated.span>
						</h1>
						<h1 className="font-mono text-4xl font-bold tracking-tighter md:text-6xl">
							<span className="font-display tracking-normal">iPhone</span> Feature
						</h1>
					</div>
					<div className="relative z-10 flex min-h-screen flex-1 flex-col items-center justify-center">
						{springs.map((spring, i) => {
							const angleInRadians =
								((isMd ? 270 : 180) * Math.PI) / 180 +
								((i / (posts.length - 1)) * 360 * Math.PI) / 180
							return (
								<Post
									{...posts[i]}
									className="absolute bg-white"
									ref={i === 0 ? cardRef : undefined}
									style={{
										'--tw-shadow': spring.progress.to(
											p =>
												`0 10px 15px -3px rgb(0 0 0 / ${
													_clamp(10 * p, 0, 1) * 0.2
												}), 0 4px 6px -4px rgb(0 0 0 / ${_clamp(10 * p, 0, 1) * 0.2})`
										),
										x: spring.progress.to(
											p =>
												(_clamp(p - 0.1, 0, 1) / (1 - 0.1)) *
												distanceX *
												Math.cos(angleInRadians)
										),
										y: spring.progress.to(
											p =>
												(_clamp(p - 0.1, 0, 1) / (1 - 0.1)) *
												distanceY *
												Math.sin(angleInRadians)
										),
										zIndex: posts.length - 1 - i,
									}}
								/>
							)
						})}
						<Post
							{..._last(posts)}
							className="absolute bg-white shadow-none"
							style={{
								zIndex: 0,
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
