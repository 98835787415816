import Head from 'next/head'
import React from 'react'

import { Footer } from '~/components/footer'
import Cta from '~/components/hero/cta'
import Faq from '~/components/hero/faq'
import Features from '~/components/hero/features'
import MacHero from '~/components/hero/mac'
import PostsHero from '~/components/hero/posts'
import Pricing from '~/components/hero/pricing'

export default function Home() {
	return (
		<>
			<Head>
				<title>
					Message Decoder - Scans your notifications, automatically puts one-time
					passcodes on your clipboard
				</title>
				<meta
					content="Message Decoder scans your notifications as they appear, automatically putting
					one-time passcodes on your clipboard. No more switching apps, no more copying
					codes, just hit paste."
					name="description"
				/>
			</Head>

			<main>
				<PostsHero />
				<MacHero />
				<Cta />
				<Features />
				<Pricing />
				<Faq />
			</main>

			<Footer className="relative z-10" />
		</>
	)
}
