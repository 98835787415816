import { animated } from '@react-spring/web'
import clsx from 'clsx'
import { forwardRef } from 'react'

export type PostProps = {
	avatarUrl?: string
	content?: string
	date?: string
	displayName?: string
	handle?: string
	imageUrl?: string
	likes?: string
	replies?: string
	reposts?: string
	views?: string
}

export default forwardRef<HTMLDivElement>(
	(
		{
			avatarUrl = 'https://pbs.twimg.com/profile_images/1111915116579086336/HKxtnLsO_reasonably_small.jpg',
			className,
			content = 'Starhopper',
			date = 'Apr 7',
			displayName = 'Elon Musk',
			handle = '@elonmusk',
			imageUrl = 'https://pbs.twimg.com/media/D3h0Ot3U0AAoX7j?format=jpg&name=large',
			likes = '',
			replies = '',
			reposts = '',
			views = '',
			...props
		}: React.HTMLAttributes<HTMLDivElement> & PostProps,
		ref
	) => (
		<animated.div
			className={clsx('mx-4 my-6 max-w-xl rounded-xl shadow-lg md:mx-auto', className)}
			{...props}
			ref={ref}
		>
			<article className="flex cursor-pointer flex-wrap items-start rounded-xl border border-gray-400 p-4 hover:bg-gray-100">
				<img src={avatarUrl} className="mr-3 h-12 w-12 rounded-full" />

				<div className="flex flex-1 flex-wrap items-start justify-start">
					<div className="flex flex-1 items-center">
						<div className="flex flex-1 items-center">
							<h3 className="mr-2 font-bold hover:underline">
								<a href="#">{displayName}</a>
							</h3>
							<span className="mr-1 text-sm text-gray-600">{handle}</span>
							<span className="mr-1 text-sm text-gray-600">·</span>
							<span className="text-sm text-gray-600">{date}</span>
						</div>
					</div>

					<div className="w-full">
						<p className="my-1">{content}</p>

						{imageUrl ? (
							<div className="rounded-lg">
								<img
									src={imageUrl}
									className="aspect-[4/3] w-full rounded-lg border object-cover object-top"
								/>
							</div>
						) : null}

						<div className="flex items-center justify-start gap-x-3 pt-2 text-sm md:gap-x-5">
							<div className="flex items-center gap-x-1 text-gray-600 hover:text-blue-500">
								<a
									href="#"
									className="flex h-8 w-8 items-center justify-center rounded-full hover:bg-blue-200 hover:text-blue-500"
								>
									<svg viewBox="0 0 24 24" className="h-5 w-5 fill-current">
										<g>
											<path d="M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"></path>
										</g>
									</svg>
								</a>
								<span>{replies}</span>
							</div>

							<div className="flex items-center gap-x-1 text-gray-600 hover:text-green-500">
								<a
									href="#"
									className="flex h-8 w-8 items-center justify-center rounded-full hover:bg-green-200 hover:text-green-500"
								>
									<svg viewBox="0 0 24 24" className="h-5 w-5 fill-current">
										<g>
											<path d="M23.77 15.67c-.292-.293-.767-.293-1.06 0l-2.22 2.22V7.65c0-2.068-1.683-3.75-3.75-3.75h-5.85c-.414 0-.75.336-.75.75s.336.75.75.75h5.85c1.24 0 2.25 1.01 2.25 2.25v10.24l-2.22-2.22c-.293-.293-.768-.293-1.06 0s-.294.768 0 1.06l3.5 3.5c.145.147.337.22.53.22s.383-.072.53-.22l3.5-3.5c.294-.292.294-.767 0-1.06zm-10.66 3.28H7.26c-1.24 0-2.25-1.01-2.25-2.25V6.46l2.22 2.22c.148.147.34.22.532.22s.384-.073.53-.22c.293-.293.293-.768 0-1.06l-3.5-3.5c-.293-.294-.768-.294-1.06 0l-3.5 3.5c-.294.292-.294.767 0 1.06s.767.293 1.06 0l2.22-2.22V16.7c0 2.068 1.683 3.75 3.75 3.75h5.85c.414 0 .75-.336.75-.75s-.337-.75-.75-.75z"></path>
										</g>
									</svg>
								</a>
								<span>{reposts}</span>
							</div>

							<div className="flex items-center gap-x-1 text-gray-600 hover:text-red-500">
								<a
									href="#"
									className="flex h-8 w-8 items-center justify-center rounded-full hover:bg-red-200 hover:text-red-500"
								>
									<svg viewBox="0 0 24 24" className="h-5 w-5 fill-current">
										<g>
											<path d="M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"></path>
										</g>
									</svg>
								</a>
								<span>{likes}</span>
							</div>

							<div className="flex items-center text-gray-600 hover:text-blue-500">
								<a
									href="#"
									className="flex h-8 w-8 items-center justify-center rounded-full hover:bg-blue-200 hover:text-blue-500"
								>
									<svg viewBox="0 0 24 24" className="h-5 w-5 fill-current">
										<g>
											<path d="M8.75 21V3h2v18h-2zM18 21V8.5h2V21h-2zM4 21l.004-10h2L6 21H4zm9.248 0v-7h2v7h-2z"></path>
										</g>
									</svg>
								</a>
								<span>{views}</span>
							</div>
						</div>
					</div>
				</div>
			</article>
		</animated.div>
	)
)
