import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'

const faqs = [
	{
		answer:
			"Yep! I still sorta can't believe Apple uses one-time passcode autofill as a strategic weapon to drive Safari adoption but here we are! Personally, if I wanted more people to use my web browser, I'd simply make a competitive web browser, alas, Message Decoder is here to help bail out failing Safari. It works using system-wide Accessibility and Clipboard frameworks, so that means OTPs arriving in any system notifications can be pasted in any Mac app. I like to think it's how Apple would've done it if Safari was run by another company :)",
		question: 'Does it work with Google Chrome / Firefox / Edge / Arc / etc web browser?',
	},
	{
		answer:
			"Yep! Any app that uses native macOS notifications to display one-time passcodes should work! Message Decoder uses the macOS Accessibility framework to read the text displayed in notifications just like you do, so anything that pops OTPs up in the upper right corner of your Mac's screen should work (and let us know if not! hi@messagedecoder.com).",
		question:
			'Does it work with Gmail / Apple Mail / Outlook / WhatsApp / Signal / etc messaging service?',
	},
	{
		answer:
			"Totally! Click on the menu bar icon, open Settings, toggle 'Show Scanner Animation' off, and you're good to go.",
		question: 'Can I disable the cool yellow scanner animation?',
	},
	{
		answer:
			"Message Decoder works 96% of the time but some one-time passcode formats are tricky. If you find a code that doesn't work, please email me a screenshot of the notification and I'll try to add support for it: hi@messagedecoder.com.",
		question: "It missed a code! Why didn't it work?",
	},
	{
		answer:
			"Message Decoder includes unlimited free scans because I love the Mac and think every Mac user deserves a seamless OTP experience. The free version starts the same as the paid version, but gets a little slower after each use until it's just 3x faster than manually tracking down codes&mdash; and if you find that annoying, there's a $24/year paid plan that includes unlimited high-speed scans. If you don't want an entire year of access, there's a $7/month plan you can email me for access to: hi@messagedecoder.com.",
		question: 'How much does it cost?',
	},
	{
		answer:
			'Yes! Message Decoder works with both Intel and Apple Silicon Macs. More information about supported Macs is available under "Does it work with my Mac?".',
		question: 'Does it work with Intel or Apple Silicon Macs?',
	},
	{
		answer:
			'Sadly no. The Mac App Store requires App Sandbox, which is great, except that after 12 years Apple still has not bothered to create an entitlement for assistive apps. Message Decoder uses the Accessibility framework to read notifications, so it does not work with App Sandbox. Sorry about that and please voice your displeasure with Apple.',
		question: 'Is it available from the Mac App Store?',
	},
	{
		answer:
			'I offer full refunds within 14 days of purchase. Beyond that, requests will be considered on a case-by-case basis. Email me: hi@messagedecoder.com. The full refunds policy is available at https://messagedecoder.com/legal/refunds.',
		question: 'Do you offer refunds?',
	},
	{
		answer:
			'Your notification data is totally private and never leaves your Mac. When a notification appears on screen, Message Decoder uses the macOS Accessibility framework to read the text displayed. That text is tokenized into a custom vector format that filters the plaintext into about two dozen binary features. That vector is used as an input into a CoreML model that runs on your Mac and detects the presence of OTPs. If it signals a code was found, Message Decoder will copy it to your clipboard and display a notification. If it signals no code was found, Message Decoder will display a notification that no code was found. Separately from your notification data, I collect anonymous usage data to understand user behavior so I can improve the product, specifically I collect the number of scans and the number of matched OTPs.',
		question: 'How does it use my data?',
	},
	{
		answer:
			'Message Decoder is built to run on Macs with macOS 11.0 Big Sur (released Fall 2020) and later, with either Intel or Apple Silicon chips. I develop, test, and personally use it with macOS 13 Ventura (released Fall 2022) on Apple Silicon. Let me know if you run into any unexpected behavior: hi@messagedecoder.com.',
		question: 'Does it work with my Mac?',
	},
	{
		answer:
			"I used the clock in my Mac's menu bar to measure how long it took me to find a one-time passcode from my email in Gmail, copy the code, go back to the login tab, paste the code, and measured 15 seconds. Message Decoder takes about 1 second to scan a notification and copy the code to your clipboard, so that’s where the 15x faster figure comes from.",
		question: 'Where does the 15x faster figure come from?',
	},
	{
		answer:
			'Hey 👋 my name is Zane Shannon, I live in San Francisco, consider myself a poster child for the power of unlimited screentime, and <a class="underline" href="https://zcs.me/articles/introducing-message-decoder">designed/wrote Message Decoder</a> with the company of my large pet dog, Winnie. <a class="underline" href="https://zcs.me">More about Winnie (and me)</a>.',
		question: 'Who made this?',
	},
	{
		answer: 'Sure: hi@messagedecoder.com.',
		question: 'Can we talk?',
	},
]

export default ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
	return (
		<div className={clsx('bg-gray-900', className)} {...props}>
			<div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
				<div className="mx-auto max-w-4xl divide-y divide-white/10">
					<h2 className="text-2xl font-bold leading-10 tracking-tight text-white">
						Frequently asked questions
					</h2>
					<dl className="mt-10 space-y-6 divide-y divide-white/10">
						{faqs.map(faq => (
							<Disclosure as="div" key={faq.question} className="pt-6">
								{({ open }) => (
									<>
										<dt>
											<Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
												<span className="text-base font-semibold leading-7">
													{faq.question}
												</span>
												<span className="ml-6 flex h-7 items-center">
													{open ? (
														<MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
													) : (
														<PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
													)}
												</span>
											</Disclosure.Button>
										</dt>
										<Disclosure.Panel as="dd" className="mt-2 pr-12">
											<p
												className="text-base leading-7 text-gray-300"
												dangerouslySetInnerHTML={{ __html: faq.answer }}
											></p>
										</Disclosure.Panel>
									</>
								)}
							</Disclosure>
						))}
					</dl>
				</div>
			</div>
		</div>
	)
}
