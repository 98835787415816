import clsx from 'clsx'
import Head from 'next/head'

import { Footer } from '~/components/footer'
import { Header } from '~/components/header'

export function Container({ className, ...props }: React.HTMLProps<HTMLDivElement>) {
	return (
		<div
			className={clsx('mx-auto max-w-7xl px-4 sm:px-6 lg:px-8', className)}
			{...props}
		/>
	)
}

export function Prose({ children, className }) {
	return <div className={clsx(className, 'prose dark:prose-invert')}>{children}</div>
}

export function MarkdownContainer({
	children,
	meta = {},
	...props
}: React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> & {
	meta?: any
}) {
	return (
		<>
			<Head>
				<title>{`${meta.title} - Message Decoder`}</title>
				<meta content={meta.description} name="description" />
			</Head>
			<Header />
			<Container className="my-16 dark:bg-slate-900 lg:my-32">
				<div className="xl:relative">
					<div className="mx-auto max-w-2xl">
						<article>
							<header className="flex flex-col">
								<h1 className="mt-6 text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
									{meta.title}
								</h1>
								{/* <time
                  dateTime={meta.date}
                  className="order-first flex items-center text-base text-zinc-400 dark:text-zinc-500"
                >
                  <span className="h-4 w-0.5 rounded-full bg-zinc-200 dark:bg-zinc-500" />
                  <span className="ml-3">{formatDate(meta.date)}</span>
                </time> */}
							</header>
							<Prose className="mt-8">{children}</Prose>
						</article>
					</div>
				</div>
			</Container>
			<Footer />
		</>
	)
}
