import {
	BoltIcon,
	ClipboardDocumentCheckIcon,
	ComputerDesktopIcon,
	CpuChipIcon,
	RectangleGroupIcon,
	RectangleStackIcon,
} from '@heroicons/react/20/solid'
import { track } from '@vercel/analytics'
import clsx from 'clsx'
import Link from 'next/link'
import { signIn } from 'next-auth/react'

import { AppleLogo, GoogleLogo } from '~/components/logo'

const features = [
	{
		description:
			'One-time passcodes automatically copied to clipboard so you can just hit paste and move on.',
		icon: ClipboardDocumentCheckIcon,
		name: 'Notification &rarr; Clipboard.',
	},
	{
		description:
			'Notifications are processed on your Mac, so you never have to worry about privacy, security, or "sunsets".',
		icon: ComputerDesktopIcon,
		name: '100% local inference.',
	},
	{
		description:
			'96% accurate one-time passcode detection, trained on 70,000 human-labeled message dataset.',
		icon: CpuChipIcon,
		name: 'Custom trained ML-model.',
	},
	{
		description:
			"Leverages the power of macOS's Accessibility framework to read text from any notification on your screen.",
		icon: RectangleStackIcon,
		name: 'Every messaging service.',
	},
	{
		description:
			'Use the tools you love like Arc or Google Chrome without suffering because Apple wants to "make Safari happen".',
		icon: RectangleGroupIcon,
		name: 'Any Mac app.',
	},
	{
		description:
			'Ultra-efficient 7 KB CoreML model parses notifications in milliseconds.',
		icon: BoltIcon,
		name: 'High-speed scans.',
	},
]

export default ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
	return (
		<div className={clsx('bg-gray-900 py-24 sm:py-32', className)} {...props}>
			<div className="mx-auto max-w-7xl px-6 lg:px-8">
				<div className="mx-auto max-w-2xl sm:text-center">
					<h2 className="text-base font-semibold leading-7 text-amber-400">
						Do one thing, do it well.
					</h2>
					<p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
						One-time passcodes, at your fingertips.
					</p>
					<p className="mt-6 text-lg leading-8 text-gray-300">
						Message Decoder is just how OTPs should work on the Mac. Fast, simple, and
						keeping you in flow, so you can cut out hackers while cutting out the busywork
						code hunting side quests.
					</p>
				</div>
			</div>
			{/* <div className="relative overflow-hidden pt-16">
				<div className="mx-auto max-w-7xl px-6 lg:px-8">
					<img
						src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
						alt="App screenshot"
						className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-white/10"
						width={2432}
						height={1442}
					/>
					<div className="relative" aria-hidden="true">
						<div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-gray-900 pt-[7%]" />
					</div>
				</div>
			</div> */}
			<div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
				<dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
					{features.map(feature => (
						<div key={feature.name} className="relative pl-9">
							<dt className="inline font-semibold text-white">
								<feature.icon
									className="absolute left-1 top-1 h-5 w-5 text-amber-500"
									aria-hidden="true"
								/>
								<span dangerouslySetInnerHTML={{ __html: feature.name }} />
							</dt>{' '}
							<dd
								className="inline"
								dangerouslySetInnerHTML={{ __html: feature.description }}
							/>
						</div>
					))}
				</dl>
				<div className="mx-auto max-w-3xl justify-center text-center lg:flex-auto lg:pt-16">
					<div className="mt-10 flex flex-col items-center justify-center gap-6 md:flex-row ">
						<button
							className="rounded-md bg-black px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
							onClick={async () => {
								track('sign-in', { provider: 'apple' })
								return signIn('apple', {
									callbackUrl: `${window.location.origin}/home`,
								})
							}}
							type="button"
						>
							<AppleLogo
								className="absolute inline-flex aspect-square h-6 text-white"
								currentColor
							/>
							<span className="ml-9 w-full font-medium">Sign Up with Apple</span>
						</button>
						<button
							className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
							onClick={async () => {
								track('sign-in', { provider: 'google' })
								return signIn('google', {
									callbackUrl: `${window.location.origin}/home`,
								})
							}}
							type="button"
						>
							<GoogleLogo className="absolute inline-flex aspect-square h-6" />
							<span className="ml-9 w-full font-medium">Sign Up with Google</span>
						</button>
						<Link
							href="/download/macos"
							className="hidden text-sm font-semibold leading-6 text-white md:flex"
							onClick={() => {
								track('download', { platform: 'macos' })
							}}
						>
							Download for macOS <span aria-hidden="true">→</span>
						</Link>
					</div>
					<p className="mt-8 text-sm leading-8 text-gray-300">
						Requires macOS 11.0 or later.
					</p>
				</div>
			</div>
		</div>
	)
}
