import clsx from 'clsx'
import Link from 'next/link'

export function NavLink({
	children,
	className,
	href,
	...props
}: React.HTMLProps<HTMLButtonElement> & { color?: string; variant?: string }) {
	return href ? (
		// @ts-expect-error: lol
		<Link
			className={clsx(
				'inline-block rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900 dark:text-slate-400 dark:hover:bg-slate-800 dark:hover:text-slate-50',
				className
			)}
			href={href}
			{...props}
		>
			{children}
		</Link>
	) : (
		// @ts-expect-error: lol
		<button
			className={clsx(
				'inline-block rounded-lg px-2 py-1 text-left text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900 dark:text-slate-400 dark:hover:bg-slate-800 dark:hover:text-slate-50',
				className
			)}
			{...props}
		>
			{children}
		</button>
	)
}
