import { CheckIcon } from '@heroicons/react/20/solid'
import { track } from '@vercel/analytics'
import clsx from 'clsx'
import Link from 'next/link'

const tiers = [
	{
		description:
			"Unlimited precious moments saved for everyone. I'm so excited to see what you do with all that time!",
		featured: false,
		features: [
			'Copies one-time passcodes in less than 5 seconds',
			'Scans all notifications, not just iMessages',
			'100% private on-your-Mac processing',
			'Unlimited personal devices',
			'Custom-trained OTP ML-model',
		],
		href: '/home',
		id: 'tier-free',
		price: 'Free',
		speed: 3,
	},
	{
		description:
			'Forget about limits for an entire year, for the price of just one New York City cocktail.',
		featured: true,
		features: [
			'Unlimited high-speed scans',
			'Copies one-time passcodes in about 1 second',
			'15x faster than manually chasing down codes',
			'Scans all notifications, not just iMessages',
			'100% private on-your-Mac processing',
			'Unlimited personal devices',
			'Custom-trained OTP ML-model',
			'One year of automatic updates',
		],
		href: '/home',
		id: 'tier-annual',
		name: 'Annual',
		price: '$24',
		pricePeriod: 'year',
		ps: 'Planning to quit logging on to go bliss out soon? Save some time in the meantime by asking about our $7/month plan: hi@messagedecoder.com.',
		speed: 15,
	},
]

export default ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
	return (
		<div
			className={clsx('relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8', className)}
			{...props}
		>
			<div
				className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl"
				aria-hidden="true"
			>
				<div
					className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#F9DD61] to-[#885F35] opacity-30"
					style={{
						clipPath:
							'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
					}}
				/>
			</div>
			<div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
				<h2 className="text-base font-semibold leading-7 text-amber-600">
					Super Simple Pricing
				</h2>
				<p className="mx-auto mt-2 max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
					The right price for you, whatever your time's worth
				</p>
			</div>
			<p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
				Message Decoder includes unlimited <span className="font-bold">free</span> scans
				and gets a little slower each time you use it&mdash; but always faster than
				manually tracking down that code. If you don't want to wait, unlimited high-speed
				scans are available on all paid plans.
			</p>
			<div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
				{tiers.map((tier, tierIdx) => (
					<div
						key={tier.id}
						className={clsx(
							tier.featured
								? 'relative bg-gray-900 shadow-2xl'
								: 'bg-white/60 sm:mx-8 lg:mx-0',
							tier.featured
								? ''
								: tierIdx === 0
								? 'rounded-t-3xl sm:rounded-b-none lg:rounded-bl-3xl lg:rounded-tr-none'
								: 'sm:rounded-t-none lg:rounded-bl-none lg:rounded-tr-3xl',
							'rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10'
						)}
					>
						<h1 className="mb-8 mt-4 flex items-baseline gap-x-4">
							<span
								className={clsx(
									tier.featured ? 'text-white' : 'text-gray-900',
									'text-8xl font-bold tracking-tight sm:text-9xl'
								)}
							>
								{tier.speed}x
							</span>

							<span
								className={clsx(
									tier.featured ? 'text-amber-400' : 'text-gray-500',
									'text-3xl sm:text-5xl'
								)}
							>
								faster
							</span>
						</h1>
						{tier.name ? (
							<h3
								id={tier.id}
								className={clsx(
									tier.featured ? 'text-amber-400' : 'text-amber-600',
									'text-base font-semibold leading-7'
								)}
							>
								{tier.name}
							</h3>
						) : null}
						<p className="mt-4 flex items-baseline gap-x-2">
							<span
								className={clsx(
									tier.featured ? 'text-white' : 'text-gray-900',
									'text-5xl font-bold tracking-tight'
								)}
							>
								{tier.price}
							</span>
							{tier.pricePeriod ? (
								<span
									className={clsx(
										tier.featured ? 'text-gray-400' : 'text-gray-500',
										'text-base'
									)}
								>
									/{tier.pricePeriod}
								</span>
							) : null}
						</p>
						<p
							className={clsx(
								tier.featured ? 'text-gray-300' : 'text-gray-600',
								'mt-6 text-base leading-7'
							)}
						>
							{tier.description}
						</p>
						<ul
							role="list"
							className={clsx(
								tier.featured ? 'text-gray-300' : 'text-gray-600',
								'mt-8 space-y-3 text-sm leading-6 sm:mt-10'
							)}
						>
							{tier.features.map(feature => (
								<li key={feature} className="flex gap-x-3">
									<CheckIcon
										className={clsx(
											tier.featured ? 'text-amber-400' : 'text-amber-600',
											'h-6 w-5 flex-none'
										)}
										aria-hidden="true"
									/>
									{feature}
								</li>
							))}
						</ul>
						<Link
							href={tier.href}
							aria-describedby={tier.id}
							className={clsx(
								tier.featured
									? 'bg-amber-500 text-white shadow-sm hover:bg-amber-400 focus-visible:outline-amber-500'
									: 'text-amber-600 ring-1 ring-inset ring-amber-200 hover:ring-amber-300 focus-visible:outline-amber-600',
								'mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10'
							)}
							onClick={() => {
								track('pricing-get-started', { tier: tier.id })
							}}
						>
							Get started today
						</Link>
						{tier.ps ? (
							<p
								className={clsx(
									tier.featured ? 'text-gray-300' : 'text-gray-600',
									'mt-6 text-sm leading-5'
								)}
							>
								{tier.ps}
							</p>
						) : null}
					</div>
				))}
			</div>
		</div>
	)
}
