import type React from 'react'
import useComponentSize from '@rehooks/component-size'
import { clamp as _clamp } from 'lodash'
import { useEffect, useMemo } from 'react'

type OnChangeParameters = {
	value: {
		progressY: number
	}
}

type UseViewportProgressParameters = {
	onChange: (p: OnChangeParameters) => void
	ref: React.MutableRefObject<HTMLElement | undefined>
}

export const useViewportProgress = ({ onChange, ref }: UseViewportProgressParameters) => {
	const { height, width } = useComponentSize(ref)
	useEffect(() => {
		const handler = () => {
			const { innerHeight, scrollY } = window
			const maxY = height + innerHeight
			const pxOnScreen = _clamp(scrollY + innerHeight - ref.current?.offsetTop, 0, maxY)
			const progressY = _clamp(pxOnScreen / maxY, 0, 1)
			onChange({ value: { progressY } })
		}

		handler()
		window.addEventListener('scroll', handler, { passive: true })

		return () => {
			window.removeEventListener('scroll', handler)
		}
	}, [height])
	return useMemo(() => ({ height, width }), [height, width])
}
