import { Popover, Transition } from '@headlessui/react'
import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { signOut, useSession } from 'next-auth/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { createGlobalStyle } from 'styled-components'

import { Button } from '~/components/button'
import { Container } from '~/components/container'
import { NavLink } from '~/components/nav-link'

function MobileNavLink({ children, href, ...props }: React.HTMLProps<HTMLButtonElement>) {
	return href ? (
		<Popover.Button as={Link} className="block w-full p-2" href={href}>
			{children}
		</Popover.Button>
	) : (
		// @ts-expect-error: lol
		<Popover.Button className="block w-full p-2" {...props}>
			{children}
		</Popover.Button>
	)
}

function MobileNavIcon({ open }) {
	return (
		<svg
			aria-hidden="true"
			className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
			fill="none"
			strokeLinecap="round"
			strokeWidth={2}
		>
			<path
				className={clsx('origin-center transition', open && 'scale-90 opacity-0')}
				d="M0 1H14M0 7H14M0 13H14"
			/>
			<path
				className={clsx('origin-center transition', !open && 'scale-90 opacity-0')}
				d="M2 2L12 12M12 2L2 12"
			/>
		</svg>
	)
}

function MobileNavigation() {
	const { pathname } = useRouter()
	const { data, status } = useSession()
	// @ts-expect-error: lol
	const isAdmin = data?.user?.isAdmin
	return (
		<Popover>
			<Popover.Button
				aria-label="Toggle Navigation"
				className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
			>
				{({ open }) => <MobileNavIcon open={open} />}
			</Popover.Button>
			<Transition.Root>
				<Transition.Child
					as={Fragment}
					enter="duration-150 ease-out"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="duration-150 ease-in"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
				</Transition.Child>
				<Transition.Child
					as={Fragment}
					enter="duration-150 ease-out"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="duration-100 ease-in"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95"
				>
					<Popover.Panel
						as="div"
						className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
					>
						{pathname === '/' ? (
							<>
								{/* <MobileNavLink href="#features">Features</MobileNavLink>
                <MobileNavLink href="#testimonials">Testimonials</MobileNavLink>
                <MobileNavLink href="#pricing">Pricing</MobileNavLink> */}
							</>
						) : null}
						{isAdmin ? (
							<>
								<MobileNavLink href="/admin">Admin</MobileNavLink>
								<hr className="m-2 border-slate-300/40" />
							</>
						) : null}

						{status === 'unauthenticated' ? (
							<MobileNavLink href="/login">Sign in</MobileNavLink>
						) : (
							<MobileNavLink onClick={async () => signOut({ callbackUrl: '/' })}>
								Sign out
							</MobileNavLink>
						)}
					</Popover.Panel>
				</Transition.Child>
			</Transition.Root>
		</Popover>
	)
}

const GlobalStyle = createGlobalStyle`html {
  --header-h: ${props => props.height ?? 0};
}`

export const Header = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
	const { pathname } = useRouter()
	const { data, status } = useSession()
	// @ts-expect-error: lol
	const isAdmin = data?.user?.isAdmin
	const ref = useRef()
	const [height, setHeight] = useState(0)
	useEffect(() => {
		if (ref.current) {
			const style = getComputedStyle(ref.current)
			setHeight(style.height)
		}
	})
	return (
		<header className={clsx('z-20', className)} {...props} ref={ref}>
			<GlobalStyle height={height} />
			<Container>
				<nav className="relative z-50 flex justify-between">
					<div className="flex items-center md:gap-x-6">
						{/* <Link aria-label="Home" href="/">
							<Logo className="relative h-16 w-auto" />
						</Link> */}
						<div className="hidden md:flex md:gap-x-6">
							{isAdmin ? (
								<>
									<NavLink href="/admin">Admin</NavLink>
								</>
							) : null}
							{pathname === '/' ? (
								<>
									{/* <NavLink href="#features">Features</NavLink>
                  <NavLink href="#testimonials">Testimonials</NavLink>
                  <NavLink href="#pricing">Pricing</NavLink> */}
								</>
							) : (
								<>{/* <NavLink href="/billing">Billing</NavLink> */}</>
							)}
						</div>
					</div>
					<div className="flex items-center gap-x-5 md:gap-x-8">
						<div className="hidden md:block">
							{status === 'unauthenticated' ? (
								<NavLink href="/login">Sign in</NavLink>
							) : (
								<NavLink onClick={async () => signOut({ callbackUrl: '/' })}>
									Sign out
								</NavLink>
							)}
						</div>
						{status === 'unauthenticated' ? (
							<Button color="blue" href="/register">
								<span>
									Get started <span className="hidden lg:inline">today</span>
								</span>
							</Button>
						) : null}
						<div className="-mr-1 md:hidden">
							<MobileNavigation />
						</div>
					</div>
				</nav>
			</Container>
		</header>
	)
}
